import React, { useEffect } from 'react';
import './Admission.css';
import { Link } from 'react-router-dom'; 
import { Card } from '../../components';

const Admission = () => {

  

  return (
    useEffect(()=>{
      document.title="EARK School - Admission"
    }, []),
    <div className="ad__main-container">
      <div className="ad__main-heading">
        <p className='ad__head'> Our Admission</p>
        <p className='ad__head-cont'>
          Currently, we are enrolling the following classes:
          Early Years (1.6 years onwards)
        </p>
        <div className="ad__course-cont">
          <p className='ad__course-off'>Nursery</p>
          <p className='ad__course-off'>Reception</p>
          <p className='ad__course-off'>Lower Primary</p>
        </div>
      </div>

      <div className="ad__list_inst">
        <div className="ls__">
          <p className='ad__inst'>
            <span className='ad__no-id'>1</span> Download the PDF form below and fill in all the correct details.
          </p>
          <p className='ad__inst'>
            <span className='ad__no-id'>2</span> Bring or send your form to the following address: 
            info@earkinternationalschool.org
          </p>
          <p className='ad__inst'>
            <span className='ad__no-id'>3</span> Pay the administrative fee to our Bank Account.
          </p>
          <div className="ad__btn_holder">
            <a href="./Application_form.pdf" target="_blank" rel="noopener noreferrer">
              <button className='ad__btn'>View PDF</button>
            </a>
          </div>
        </div>

        <div className="ad__footer">
          <p>We shall process your admission and update you with our final decision within 5 days of receiving payment and all documentation.</p>
        </div>
      </div>

      <div>
        <Card/>
      </div>
    </div>
  );
}

export default Admission;
