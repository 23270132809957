import th from '../assets/th.jpg';
// import employeeblack from '../assets/images/employeeblack.webp';
import learning from '../assets/learning.webp';
import extra from '../assets/Extra curricular.jpg';
import personal from '../assets/personalized learning 1.jpg';


export const Curriculum = [
  {
    name: "Learning and Language Support",
    image: learning,
    description: "Our learners are from different ethnic and cultural backgrounds. We embrace their learning difficulties and support their needs. We have learning support programs and language support programs that run during school hours. We identify the learners who need support, we help them gain confidence and a sense of accomplishment when they overcome challenges, leading to their improved self-esteem. Providing language support to foreign learners of English at our school is crucial to help them succeed academically and feel included and develop strong language skills that will benefit them throughout their lives."
  },
  {
    name: "Extracurricular Activities",
    image: extra,
    description: "At EARK, we understand the importance of activities that allow our children the opportunity to explore different areas of interest that will increase their self-confidence, help them develop new skills, enhance their creativity, foster social connections and build leadership skills. We firmly believe in providing comprehensive learning opportunities for our children, which has given rise to the introduction of various extra-curricular activities that enable them to explore their interests and hone new skills. From music lessons to sports training, learners get an opportunity to explore and express themselves beyond classrooms.Join us in shaping the future leaders, thinkers, and creators. Enrol today and discover the EARK difference."
  },
  {
    name: "Personalized Learning Path",
    image:personal,
    description: "We recognize that each student is unique and strive to provide personalized learning paths that cater to individual needs and interests. Our dedicated faculty works closely with students to identify their strengths and areas for growth, guiding them towards their full potential."
  },
 
];
export const des = [
  {
    id: "1",
    heading: "Electronic Devices & Circuits Lab",
    "sub-head":
      "",
    pic:th,
    link:"https://youtu.be/ZfA004WzxEU",
    content_1:
      "",
    content_2:
      "The Electronic Devices and Circuits Laboratory has been established over an area of 159 sq.m. Here courses such as Digital Electronics Lab for III Sem, Linear Integrated Circuits Lab for V Sem and Electronic Circuits & Simulation Lab for IV Sem, are conducted. This lab is equipped with Electronic Workbenches with Regulated  Power supply, Function Generator, Cathode Ray Oscilloscope, Micro ammeter and Voltmeter, Bread Board and Personal computer with Mulitsim and ORCAD PSPICE Perpetual License (Software) and Digital trainer Kits.",
  },
]