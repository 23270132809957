import React from 'react';
import './Card.css'; // Assuming you will have the same CSS in a separate file

const Card = () => {
  return (
    <div className="card-container">
      <div className="card">
        <div className="card-face card-face1">
          <div className="card-content">
            
            <h3>ACADEMIC CALENDER<br/>2024-2025</h3>
          </div>
        </div>
        <div className="card-face card-face2">
          <div className="card-content">
            <a href="./EARK_ACADEMIC_CALENDER.pdf" target='blank'>VIEW PDF</a>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-face card-face1">
          <div className="card-content">
            
            <h3>Fees Schedule<br/>2023-2024</h3>
          </div>
        </div>
        <div className="card-face card-face2">
          <div className="card-content">
            <a href="./Schedule_for_fees.pdf" target='blank'>VIEW PDF</a>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-face card-face1">
          <div className="card-content">
            
            <h3>Dress Code for<br/> Pre Primary Students</h3>
          </div>
        </div>
        <div className="card-face card-face2">
          <div className="card-content">
            
            <a href="./Pre_primary_dress_code.pdf" target='blank'>VIEW PDF</a>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-face card-face1">
          <div className="card-content">
            
            <h3>Dress Code for Primary Students</h3>
          </div>
        </div>
        <div className="card-face card-face2">
          <div className="card-content">
            <a href="./Primary_dress_code.pdf" target='blank'>VIEW PDF</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
