import React,{ useEffect } from 'react'
import './Future_Plans.css'
import { Slideshow } from '../../components'
import {future_plans_slides} from '../../constants/FuturePlans'
const Future_Plans = () => {
  return (
    useEffect(() => {
        document.title = "EARK School - Future Plans";
      }, []),

    <div>
        <div className="future_slides">
            <Slideshow images={future_plans_slides} />
        </div>
    </div>
  )
}

export default Future_Plans