import Angela from '../assets/teacher1.webp';
import Mary from '../assets/teacher2.webp';
import Chuckie from '../assets/teacher3.webp';
import mwaka from '../assets/Mwaka.webp';
import diana from '../assets/Diana.webp';

export const teacher = [
  {
    name: "Angela Chibesa",
    image: Angela,
     description: "Angela Chibesa is a dedicated Early Childhood and Special Education teacher hailing from Lusaka, Zambia. She holds a diploma in Early Childhood Education and Special Education, which she applies with passion and expertise in her teaching career. Her teaching philosophy is deeply rooted in her belief that every child deserves the opportunity to thrive, regardless of their background or abilities. Among the various subjects she teaches, pre-math holds a special place in her heart. She finds joy in nurturing young minds and laying the groundwork for their future academic success.Beyond the classroom, Angela Chibesa is deeply committed to her community. She plays an active role in charity work through her involvement in the Catholic Church where she leads a church group, exemplifying her dedication to service and leadership.In her personal life, Angela enjoys a variety of hobbies. She enjoys listening to music, watching movies and immersing herself in novels. Cooking is another of her passions where she expresses her creativity and love for others. Through her professional and personal pursuits, Angela Chibesa continues to make a positive impact on the lives of those around her."
    },
  {
    name: "Ms. Mary Nyirenda",
    image:Mary ,
    description: "Mary Nyirenda is a dedicated teacher with over two years of experience in fostering the social and academic growth of young learners.Her passion for teaching stems from a deep commitment to nurturing every child's potential and creating an inclusive learning environment.Throughout her career, Mary Nyirenda has successfully implemented individualized instructions strategies that cater to students ' unique needs, resulting in improved literacy rates among struggling readers in her classroom. She has taught Grade 3 , 4 and 5. She's currently teaching the year 4 at EARK International School. She enjoys jogging, listening to music, teaching kids and reading motivational books.In conclusion, Mary Nyirenda loves teaching because it allows her to form meaningful relationship with her students.These connections create a supportive classroom environment where students feel valued and understood. "
  },
  {
    name: "Chuckie Musamba Malambo",
    image: Chuckie,
    description: "My names are Chuckie Musamba Malambo, Born in Lusaka Zambia. Got a Bachelors Degree in primary education at Chalimbana University, a Diploma in primary and a diploma in Early Childhood education. I worked at vinorite for 4 years then joined family legacy mission Zambia were I worked for 9 years as a class teacher. Currently am with Eark International school. I love to cook, back,gardening and swimming. I am a very creative person who loves arts and crafts. I enjoy my teaching job because I love working with children. In my years of teaching I have taught from grade  1 to 9.I currently teaching grade 1 and 2."
  },
  {
    name: "Mwaka Mweempwa Sichingabula",
    image: mwaka,
    description: "Hi there! I’m Mwaka Mweempwa Sichingabula and I'm an educator with a genuine passion for sparking curiosity and growth in students. Although I’ve only been teaching since last year, I’ve had the joy of teaching a range of classes, from Year 8 ICT and French in secondary school to stepping in as a cover teacher for Nursery. This year, I’m teaching Reception through to Year 4, and every day brings new excitement and learning opportunities for both me and my students. With a background in both teaching and technology, I strive to bring creativity and a tech-savvy twist to the classroom, making learning engaging and accessible. Watching my students grow and succeed keeps me inspired. Outside of school, I’m a big fan of good food, cozy rainy days, and creative projects. You’ll likely find me cooking up a favorite dish like mac and cheese or diving into a new tech idea. I believe that “teaching is the one profession that creates all others,” and I’m passionate about making a positive impact wherever I can. To me, learning should be as fun and inviting as life itself."
  },
  {
    name: "Diana Chanda Mutale",
    image: diana,
    description:"A  passionate and gifted educator, I have dedicated my life to nurturing young minds. With 20 years of teaching experience, I have developed a unique ability to make learning fun, engaging, and accessible for students of all ages and abilities."
  }
];
export const des = [
  {
    id: "1",
    heading: "Electronic Devices & Circuits Lab",
    "sub-head":
      "",
    pic: Angela,
    link:"https://youtu.be/ZfA004WzxEU",
    content_1:
      "",
    content_2:
      "The Electronic Devices and Circuits Laboratory has been established over an area of 159 sq.m. Here courses such as Digital Electronics Lab for III Sem, Linear Integrated Circuits Lab for V Sem and Electronic Circuits & Simulation Lab for IV Sem, are conducted. This lab is equipped with Electronic Workbenches with Regulated  Power supply, Function Generator, Cathode Ray Oscilloscope, Micro ammeter and Voltmeter, Bread Board and Personal computer with Mulitsim and ORCAD PSPICE Perpetual License (Software) and Digital trainer Kits.",
  },
]