import { useState, React } from 'react';
import { Link } from 'react-router-dom'; 
import './Home.css'
import flyer from '../../assets/flyer.jpg'

const Home = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = () => {
    const mailtoLink = `mailto:info@earkinternationalschool.org?subject=Contact from ${name}&body=Name: ${name}%0D%0AEmail: ${email}%0D%0AMobile: ${mobile}%0D%0AMessage: ${message}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className='home-head'>
      <div className='home'>
        <div className='home_hero_container'>
            <div className='home_scl_name'>
                <p className='scl_name'>EARK<br/>International<br/>School</p>
            </div>
            <div className='home_description'>
              <p>We prepare today's learners to confidently embrace challenges as adaptable, empathetic global citizens. </p>
            </div>
            <div className='home_img_btns'>
            <Link to="/admission">
              <button type='' className='home_apply_btn'>Apply Now</button>
              </Link>
              <a href={flyer} className="home_broucher_btn" target="_blank" rel="noopener noreferrer">
    Get Brochure
</a>

            </div>
        </div>

        <div className='home_content'>
          <div className='home_content_heading'>Learning Begins With Us​</div>
          <div className='home_para'>
            <div className='home_para1'>Welcome to EARK International School, a leading educational institution renowned for our commitment to academic excellence and personal growth, Located in Lusaka, Zambia. We provide a stimulating environment that fosters a love for learning, nurtures well-rounded individuals, and inspires curiosity, critical thinking, and creativity.</div>
            <div className='home_para2'>At EARK International School, we believe in empowering students to excel both academically and in life. Our innovative curriculum, experienced faculty and state-of-the-art facilities combine to create a unique learning experience that prepares students for success in the 21st century.</div>
          </div>
        </div>

        <div className='contact_sec'>
          <section className="message-box">
            <p className='contact-h2'>CONTACT US</p>
            <form onSubmit={handleSubmit} className="contact-form">
              <div className='form-group'>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  placeholder='Name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  placeholder='Email address'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor="message">Mobile number</label>
                <input
                  type="tel"
                  placeholder='Mobile number'
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  required
                />
              </div>
              <div className='form-group'>
                <textarea
                  id='msg_text'
                  placeholder='Message'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows="4"
                />
              </div>
              <div className='submit-button'>
                <button className='submit-button' onClick={handleSubmit}>Submit</button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Home;
