import React, { useState, useEffect, useRef } from "react";
import "./About.css";
import "../../index.css";


import galleryten from "../../assets/galleryten.webp";
import gallerynine from "../../assets/gallerynine.webp";
import galleryeight from "../../assets/galleryeight.webp";
import galleryseven from "../../assets/galleryseven.webp";
import gallerysix from "../../assets/gallerysix.webp";
import galleryfive from "../../assets/galleryfive.webp";
import galleryfour from "../../assets/galleryfour.webp";
import gallerythree from "../../assets/gallerythree.webp";
import gallerytwo from "../../assets/gallerytwo.webp";
import galleryone from "../../assets/galleryone.webp";
import gallery11 from "../../assets/galleryten.jpg";
import gallery12 from "../../assets/gallery11.jpg";
import gallery13 from "../../assets/gallery12.jpg";
import gallery14 from "../../assets/gallery13.jpg";
import gallery15 from "../../assets/gallery14.jpg";
import gallery16 from "../../assets/gallery15.jpg";
import gallery17 from "../../assets/gallery16.jpg";

import missionIcon from "../../assets/mission_icon.svg";
import visionIcon from "../../assets/vission_icon.svg";


const images = [
  galleryone,
  gallery11,
  gallerytwo,
  gallery12,
  gallerythree,
  gallery13,
  galleryfour,
  gallery14,
  galleryfive,
  gallery15,
  gallerysix,
  gallery16,

  galleryseven,
 
  galleryeight,
  gallerynine,
  galleryten,
  
  
  
 

 
];
const ImageToggler = ({ images, interval }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const toggleInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(toggleInterval); 
  }, [images, interval]);

  return (
    <img
      className="Gallery_image"
      src={images[currentImageIndex]}
      alt={`Toggling image ${currentImageIndex}`}
    />
  );
};


const About = () => {
  const [isInView, setIsInView] = useState(false);
  const evenImages= [
    galleryone,
    galleryseven,
    gallerythree,
    gallery15,
    galleryfive,
    gallery13,
    gallerynine,
    gallery11,
   
    gallery16,
    // gallerytwo,
    // galleryfour,
    // gallery14,
    // gallerysix,
    // galleryeight,
    // gallery15,
    // galleryten,
    // gallery13,
    // galleryfive,
    // gallery12,
    // gallerynine,
    // gallery11,
  ];
  const oddImages = [
  
    // gallery16,
    gallerytwo,
    galleryfour,
    gallery14,
    gallerysix,
    gallery12,
    galleryeight,
    galleryten,
     gallery17,
    // gallery15,
    
    // gallery13,
    // galleryfive,
    // gallery12,
    // gallerynine,
    // gallery11,
   
  
  ];

  const elementRef = useRef(null);

  useEffect(() => {
    const currElement = elementRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        } else {
          setIsInView(false);
        }
      });
    });

    if (currElement) {
      observer.observe(currElement);
    }

    document.title = "EARK-school";

    // const evenInterval = setInterval(() => {
    //   setEvenImages((prevImages) =>
    //     prevImages.map(
    //       (img) => images[(images.indexOf(img) + 10) % images.length]
    //     )
    //   );
    // }, 3000); 

  
    // const oddInterval = setInterval(() => {
    //   setOddImages((prevImages) =>
    //     prevImages.map(
    //       (img) => images[(images.indexOf(img) + 10) % images.length]
    //     )
    //   );
    // }, 3000); 

  
    // return () => {
    //   if (currElement) {
    //     observer.unobserve(currElement);
    //   }
    //   clearInterval(evenInterval);
    //   clearInterval(oddInterval);
    // };
  }, []);

  const baseStyle = {
    transition: "opacity 1.5s ease-in-out",
  };


  const dynamicStyle = isInView ? { opacity: "1" } : { opacity: "0" };


  const combinedStyle = { ...baseStyle, ...dynamicStyle };

  return (
    useEffect(()=>{
      document.title="EARK School - About Us"
    },[]),
    <div className="abt_main">
    <div className="eark__abt_main_container">
      <div className="eark__abt_card_holder">
        <div className="eark__abt_card_sml">
          <img src={visionIcon} className="mission_logo" alt="mission" />
          <div className="eark__abt_card_heading">
            <h2>Our Vision</h2>
          </div>
          <p>
            EARK international school’s vision is to prepare today’s learners to
            confidently embrace challenges as adaptable, empathetic global
            citizens who will be active contributors for a more sustainable
            world.
          </p>
        </div>

        <div className="eark__abt_card_big">
          <img src={missionIcon} className="vision_logo" alt="vision" />
          <div className="eark__abt_card_heading">
            <h2>Our Mission</h2>
          </div>
          <p>Our mission is to INSPIRE, NOURISH, and SUPPORT our learners.</p>
          <ul className="abt__custom-list">
            <li>
              <span id="abt__card_hgt">Inspire</span> our diverse community of
              learners to take proactive roles as responsible global citizens.
            </li>
            <li>
              <span id="abt__card_hgt">Nourish</span> the minds and spirit of
              our students in a safe, respectful, and caring environment.
            </li>
            <li>
              <span id="abt__card_hgt">Support</span> students to achieve their
              individual potential and become well-rounded, life-long learners.
            </li>
          </ul>
        </div>
      </div>
      <div
        ref={elementRef}
        className={`circle-container ${isInView ? "expanded" : " "}`}
      ></div>
      <div className="eark__abt_video-container">
        {/* {hovered && ( */}
        <iframe
          src="https://drive.google.com/file/d/1zxYwEzE88Co5DslCMhlpXrQ6CA0pC2rB/preview"
          className={`eark__abt_video`}
          style={combinedStyle}
          title="Video"
        ></iframe>
        {/* )} */}
                 
      </div>

        <div className="abt__school-head">
          <p>
            We envision a future where we nurture and guide EARK students to
            become not only knowledgeable individuals but also happy and
            compassionate global citizens.
          </p>
          <p>
            <span id="abt__aut">- School Head</span>
          </p>
        </div>
      </div>

      <div className="Gallery_whole">
  <div className="Gallery_whole_container">
    <div className="Gallery_heading">
      <h1>Gallery</h1>
    </div>
    <div className="Gallery_grid">
      <div className="card_one">
        <div className="Gallery_top_image">
        <a href={evenImages[0]} target="_blank" rel="noopener noreferrer">
            <ImageToggler images={[evenImages[0], oddImages[0]]} interval={5000} />
          </a>
        </div>
      </div>

      <div className="card_two">
        <div className="Gallery_top_image">
        <a href={oddImages[1]} target="_blank" rel="noopener noreferrer">
            <ImageToggler images={[oddImages[1], evenImages[1]]} interval={5000} />
          </a>
        </div>
      </div>

      <div className="card_three">
        <div className="Gallery_top_image">
        <a href={evenImages[2]} target="_blank" rel="noopener noreferrer">
            <ImageToggler images={[evenImages[2], oddImages[2]]} interval={5000} />
          </a>
        </div>
      </div>

      <div className="card_four">
        <div className="Gallery_top_image">
        <a href={oddImages[3]} target="_blank" rel="noopener noreferrer">
            <ImageToggler images={[oddImages[3], evenImages[3]]} interval={5000} />
          </a>
        </div>
      </div>

      <div className="card_eight">
        <div className="Gallery_top_image">
        <a href={evenImages[4]} target="_blank" rel="noopener noreferrer">
            <ImageToggler images={[evenImages[4], oddImages[4]]} interval={5000} />
          </a>
        </div>
      </div>

      <div className="card_five">
        <div className="Gallery_top_image">
        <a href={oddImages[5]} target="_blank" rel="noopener noreferrer">
            <ImageToggler images={[oddImages[5], evenImages[5]]} interval={5000} />
          </a>
        </div>
      </div>

      <div className="card_six">
        <div className="Gallery_top_image">
        <a href={evenImages[6]} target="_blank" rel="noopener noreferrer">
            <ImageToggler images={[evenImages[6], oddImages[6]]} interval={5000} />
          </a>
      
        </div>
      </div>
  

      <div className="card_seven">
        <div className="Gallery_top_image">
        <a href={evenImages[4]} target="_blank" rel="noopener noreferrer">
            <ImageToggler images={[evenImages[7], oddImages[1]]} interval={5000} />
          </a>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>

  );
};

export default About;
