import React from 'react';
import callIcon from '../../assets/call_icon.svg';
import locationIcon from '../../assets/location_icon.svg';
import mailIcon from '../../assets/mail_icon.svg';
import logo from '../../assets/footer_logo.svg';
import insta from '../../assets/insta_icon.svg';
import facebook from '../../assets/Facebook.svg';
import './Footer.css'; 

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_container">
        <div className='footer_logo'>
            <img src={logo} alt='logo'/>
        </div>
        <div className="footer_contact">
          <p className="footer_heading">CONTACT DETAILS</p>
          <div className="footer_heading_underline"></div>
        
          <ul className="footer_info">
           <div className='soc'></div>
                <li className="footer_social_icons">
                <a href="https://www.instagram.com/eark_international_school_zm/" target="_blank" rel="noopener noreferrer">
                  <img className="footer_icon_soc" src={insta} alt="Instagram" />
                </a>
                <a href="https://www.facebook.com/earkinternationalschool" target="_blank" rel="noopener noreferrer">
                  <img className="footer_icon_soc" src={facebook} alt="Facebook" />
                </a>
              </li>

              <li className="footer_social">
                <span>
                  <a href="tel:+260 955000222">+260 955000222</a> / 
                  <a href="tel:+260 211 240055">+260 211 240055</a>
                </span>
                <img className='footer_icon call' src={callIcon} alt='call'/>
              </li>
              <li className="footer_social">
                <span><a href="mailto:info@earkinternationalschool.org">info@earkinternationalschool.org</a></span>
                <img className='footer_icon mail' src={mailIcon} alt="mail"/>
              </li>
              <li className="footer_social">
                <span className='footer-map'><a href="https://maps.app.goo.gl/mtMMi5MEVxtgyUoFA">EARK International School, 9700 Chudleigh, Lusaka.</a></span>
                <img className='footer_icon mail' src={locationIcon} alt="mail"/>
              </li>
            </ul>

        </div>


    
            
      </div>
    </div>
  );
};

export default Footer;
